import { useRef, useState } from "react";
import useOnScroll from "../../hooks/useOnScroll";

const MenuSortButtons = ({ setChosenDisplay }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [fadeoutOneDisplay, setFadeoutOneDisplay] = useState(false);
  const [fadeoutTwoDisplay, setFadeoutTwoDisplay] = useState(true);

  const containerRef = useRef(null);

  // Track user's scroll on menu-sort-buttons, and display fadeouts as needed
  useOnScroll(containerRef.current, () => {
    const scrollX = containerRef.current.scrollLeft;

    if (scrollX < 40) {
      setFadeoutOneDisplay(false);
    } else {
      setFadeoutOneDisplay(true);
    }

    const maxScrollDist =
      containerRef.current.scrollWidth - containerRef.current.clientWidth;
    if (scrollX < maxScrollDist - 40) {
      setFadeoutTwoDisplay(true);
    } else {
      setFadeoutTwoDisplay(false);
    }
  });

  return (
    <div className="menu-sort-buttons" ref={containerRef}>
      <div className={`fadeout one ${fadeoutOneDisplay ? "display" : ""}`} />
      <button
        className={`complete-menu ${activeTab === 0 ? "active" : ""}`}
        onClick={() => {
          setChosenDisplay("");
          setActiveTab(0);
        }}
      >
        complete menu
      </button>
      <button
        className={activeTab === 1 ? "active" : ""}
        onClick={() => {
          setChosenDisplay("go green");
          setActiveTab(1);
        }}
      >
        go green
      </button>
      <button
        className={activeTab === 2 ? "active" : ""}
        onClick={() => {
          setChosenDisplay("bulk me up");
          setActiveTab(2);
        }}
      >
        bulk me up
      </button>
      <button
        className={activeTab === 3 ? "active" : ""}
        onClick={() => {
          setChosenDisplay("nourish my body");
          setActiveTab(3);
        }}
      >
        nourish my body
      </button>
      <button
        className={activeTab === 4 ? "active" : ""}
        onClick={() => {
          setChosenDisplay("boost my immune system");
          setActiveTab(4);
        }}
      >
        boost my immune system
      </button>
      <button
        className={activeTab === 5 ? "active" : ""}
        onClick={() => {
          setChosenDisplay("help me lose weight");
          setActiveTab(5);
        }}
      >
        help me lose weight
      </button>
      <button
        className={activeTab === 6 ? "active" : ""}
        onClick={() => {
          setChosenDisplay("acai bowls");
          setActiveTab(6);
        }}
      >
        acai bowls
      </button>
      <div className={`fadeout two ${fadeoutTwoDisplay ? "display" : ""}`} />
    </div>
  );
};

export default MenuSortButtons;
