import { useState } from "react";
import Ingredients from "./columns/Ingredients";
import NutritionFacts from "./columns/NutritionFacts";
import GlutenFree from "./columns/GlutenFree";
import Vegan from "./columns/Vegan";
import Prices from "./columns/Prices";
import axios from "axios";

const TableRow = ({ smoothie, ingredients, sections, setReload }) => {
  const [editing, setEditing] = useState(false);
  const [chosenIngredients, setChosenIngredients] = useState(
    smoothie.ingredients.split(", ")
  );
  const [chosenGlutenFree, setChosenGlutenFree] = useState(
    smoothie.gluten_free
  );
  const [chosenVegan, setChosenVegan] = useState(smoothie.vegan);
  const [chosenMediumPrice, setChosenMediumPrice] = useState(
    smoothie["24oz_price"]
  );
  const [chosenLargePrice, setChosenLargePrice] = useState(
    smoothie["32oz_price"]
  );
  const [chosenNutritionFacts, setChosenNutritionFacts] = useState(
    JSON.parse(smoothie.nutrition_facts)
  );
  const [deleteWindowOpen, setDeleteWindowOpen] = useState(false);

  const handleUpdate = async () => {
    console.log("Requesting smoothie update...");
    const updateSmoothie = await axios.put("/api/smoothies", {
      name: smoothie.name,
      ingredients: chosenIngredients,
      glutenFree: chosenGlutenFree,
      vegan: chosenVegan,
      mediumPrice: chosenMediumPrice,
      largePrice: chosenLargePrice,
      nutritionFacts: chosenNutritionFacts,
    });

    console.log(updateSmoothie.data);

    setEditing(false);
    setReload((prev) => !prev);
  };

  const handleDelete = async () => {
    console.log("Requesting smoothie deletion...");
    const deleteSmoothie = await axios.delete(
      `/api/smoothies/${smoothie.name}/${smoothie.section}`
    );

    console.log(deleteSmoothie.data);

    setEditing(false);
    setDeleteWindowOpen(false);
    setReload((prev) => !prev);
  };

  return (
    <tr>
      <td>
        <p>{smoothie.name}</p>
      </td>
      <td>
        <p>{smoothie.section}</p>
      </td>
      <td style={{ width: "40%" }}>
        <Ingredients
          editing={editing}
          chosenIngredients={chosenIngredients}
          setChosenIngredients={setChosenIngredients}
          ingredients={ingredients}
        />
      </td>
      <td className="gfv">
        <GlutenFree
          editing={editing}
          smoothie={smoothie}
          chosenGlutenFree={chosenGlutenFree}
          setChosenGlutenFree={setChosenGlutenFree}
        />
      </td>
      <td className="gfv">
        <Vegan
          editing={editing}
          smoothie={smoothie}
          chosenVegan={chosenVegan}
          setChosenVegan={setChosenVegan}
        />
      </td>
      <td className="prices">
        <Prices
          editing={editing}
          smoothie={smoothie}
          chosenMediumPrice={chosenMediumPrice}
          setChosenMediumPrice={setChosenMediumPrice}
          chosenLargePrice={chosenLargePrice}
          setChosenLargePrice={setChosenLargePrice}
        />
      </td>
      <td style={{ width: "33%" }}>
        <NutritionFacts
          editing={editing}
          chosenNutritionFacts={chosenNutritionFacts}
          setChosenNutritionFacts={setChosenNutritionFacts}
        />
      </td>
      <td className="edit-btns">
        {!editing && <button onClick={() => setEditing(!editing)}>Edit</button>}
        {editing && (
          <>
            <button onClick={handleUpdate}>Save</button>
            <button onClick={() => setDeleteWindowOpen(true)}>Delete</button>
          </>
        )}
      </td>
      {deleteWindowOpen && (
        <div className="delete-window">
          This is an irreversible action. Are you sure that you want to delete
          this smoothie from the database?
          <div className="delete-buttons">
            <button onClick={handleDelete}>Yes</button>
            <button onClick={() => setDeleteWindowOpen(false)}>No</button>
          </div>
        </div>
      )}
    </tr>
  );
};

export default TableRow;
