import { useState } from "react";
import { credentialLoginRequest } from "./util/tryLogin";

const Login = ({ setLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginAttempts, setLoginAttempts] = useState(false);

  // Check to see if user is already logged in

  // Send Login Request
  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginRequestResponse = await credentialLoginRequest(email, password);

    if (!loginRequestResponse) {
      setLoginAttempts((prev) => prev + 1);
    }

    setLoggedIn(loginRequestResponse);
  };

  return (
    <>
      {loginAttempts > 0 && (
        <div className="error-message">
          Incorrect email or password. Please try again.
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <h1>Login</h1>
        <label htmlFor="email">Email:</label>
        <input
          id="email"
          placeholder="Email"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="password">Password:</label>
        <input
          id="password"
          placeholder="Password"
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input type="submit" value="Login" />
      </form>
    </>
  );
};

export default Login;
