const Vegan = ({ editing, smoothie, chosenVegan, setChosenVegan }) => {
  return (
    <>
      <div>
        <input
          type="radio"
          id={`${smoothie.name}_vegan_true`}
          name={`${smoothie.name}_vegan_status`}
          disabled={!editing}
          defaultChecked={chosenVegan ? "checked" : ""}
          onChange={() => setChosenVegan(1)}
        />
        <label for={`${smoothie.name}_vegan_true`}>True</label>
      </div>
      <div>
        <input
          type="radio"
          id={`${smoothie.name}_vegan_false`}
          name={`${smoothie.name}_vegan_status`}
          disabled={!editing}
          defaultChecked={chosenVegan ? "" : "checked"}
          onChange={() => setChosenVegan(0)}
        />
        <label for={`${smoothie.name}_vegan_false`}>False</label>
      </div>
    </>
  );
};

export default Vegan;
