import "./Home.css";
import { useRef, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import useOnOutsideClick from "../../hooks/useOnOutsideClick";

import smoothieicon from "../../assets/smoothieicon.jpg";
import boostersicon from "../../assets/boostersicon.png";
import mappinicon from "../../assets/mappin.png";
import theemerald from "../../assets/theemerald.jpg";
import thebuilder from "../../assets/thebuilder.jpg";
import berryberry from "../../assets/berryberry.jpg";
import pbj from "../../assets/pbj.jpg";

const Home = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCgfhnWjjc-03CI_CyiiMyqDb7R9yGj8es",
  });

  const [mapOpen, setMapOpen] = useState(false);

  const mapRef = useRef(null);

  useOnOutsideClick(mapRef, () => setMapOpen(false));

  return (
    <div className="Home">
      {mapOpen && isLoaded && (
        <div className="map-modal">
          <div className="map" ref={mapRef}>
            <GoogleMap
              mapContainerClassName="map-container"
              center={{ lat: 48.74403, lng: -122.46112 }}
              zoom={15}
            >
              <Marker position={{ lat: 48.74403, lng: -122.46112 }} />
            </GoogleMap>
          </div>
          <button
            className="close-btn"
            onClick={() => setMapOpen(false)}
          ></button>
        </div>
      )}
      <section className="top">
        <div className="title">
          <div className="t">
            <h1>Emerald City Smoothie, Bellingham</h1>
            <p>Located at 1058 Lakeway Drive, next to Whole Foods</p>
          </div>

          <div className="home-buttons">
            <button onClick={() => setMapOpen(true)} className="btn">
              <img src={mappinicon} alt="Map Pin" />
              Map
            </button>
            <a
              href="https://www.emeraldcitysmoothie.com/locations"
              className="btn"
            >
              Find another location
            </a>
          </div>
        </div>
      </section>

      <section className="how-to-order">
        <h3>how to order online</h3>
        <div className="steps">
          <div className="step">
            <p>Select 'Order Now'</p>
            <a
              className="order-button"
              href="https://order.online/store/emerald-city-smoothie-bellingham-970293/?hideModal=true&pickup=true"
            >
              Order Now
            </a>
          </div>
          <div className="step">
            <p>Select Your Smoothie</p>
            <img
              id="smoothies-icon"
              src={smoothieicon}
              alt="Smoothie Choices"
            />
          </div>
          <div className="step">
            <p>Add Boosters</p>
            <img id="boosters-icon" src={boostersicon} alt="Booster Choices" />
          </div>
        </div>
      </section>

      <section className="favorites">
        <h2>favorites</h2>
        <div className="favorites-container">
          <div className="favorite">
            <div className="smoothie-image">
              <img src={theemerald} alt="The Emerald"></img>
            </div>
            <div className="description">
              <h4>The Emerald</h4>
              <p className="centered-text">
                As one of our most popular smoothies, this guy is packed full of
                antioxidant goodness. The banana, blueberry, and kale work
                hand-in-hand to reduce free-radicals in your body. Add peanut
                butter to make it a meal!
              </p>
              <span>Ingredients:</span>
              <ul>
                <li>Blueberry</li>
                <li>Kale + Spinach</li>
                <li>Strawberry</li>
                <li>Banana</li>
                <li>Whey Protein (21g)</li>
              </ul>
            </div>
          </div>
          <div className="favorite">
            <div className="smoothie-image">
              <img src={thebuilder} alt="The Builder"></img>
            </div>
            <div className="description">
              <h4>The Builder</h4>
              <p className="centered-text">
                The biggest and baddest of smoothies. The builder is a meal and
                a half in a cup, perfect for filling up with tons of protein.
                Coming in three different flavors, it has got some variation to
                fit what you're feeling.
              </p>
              <span>Ingredients:</span>
              <ul>
                <li>Ice Cream</li>
                <li>Non-fat Milk</li>
                <li>Strawberry, Chocolate, or Vanilla</li>
                <li>Gainer</li>
                <li>Banana</li>
                <li>Peanut Butter</li>
                <li>Whey Protein (24g)</li>
              </ul>
            </div>
          </div>
          <div className="favorite">
            <div className="smoothie-image">
              <img src={berryberry} alt="Berry Berry"></img>
            </div>
            <div className="description">
              <h4>Berry Berry</h4>
              <p className="centered-text">
                A classic take on a berry packed smoothie with protein to add
                some extra pep in your step. Perfect for kids and adults alike,
                this smoothie is one you can't go wrong with!
              </p>
              <span>Ingredients:</span>
              <ul>
                <li>Blueberry</li>
                <li>Raspberry</li>
                <li>Strawberry</li>
                <li>Whey Protein (9g)</li>
              </ul>
            </div>
          </div>
          <div className="favorite">
            <div className="smoothie-image">
              <img src={pbj} alt="PB & J"></img>
            </div>
            <div className="description">
              <h4>PB & J</h4>
              <p className="centered-text">
                A delicious addition to our list of meal-replacement smoothies.
                The PB+J will forever go down as one of the most popular
                smoothies of all time!
              </p>
              <span>Ingredients:</span>
              <ul>
                <li>Blueberry</li>
                <li>Raspberry</li>
                <li>Marionberry</li>
                <li>Strawberry</li>
                <li>Peanut Butter</li>
                <li>Whey Protein (9g)</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
