const Prices = ({
  editing,
  smoothie,
  chosenMediumPrice,
  setChosenMediumPrice,
  chosenLargePrice,
  setChosenLargePrice,
}) => {
  return (
    <>
      {smoothie["24oz_price"] ? (
        <p>
          24oz. Price: $
          <input
            disabled={!editing}
            value={chosenMediumPrice}
            onChange={(e) => setChosenMediumPrice(e.target.value)}
            type="number"
            min="0.01"
            max="999"
          />{" "}
        </p>
      ) : (
        <></>
      )}

      {smoothie["32oz_price"] ? (
        <p>
          32oz. Price: $
          <input
            disabled={!editing}
            value={chosenLargePrice}
            onChange={(e) => setChosenLargePrice(e.target.value)}
            type="number"
            min="0.01"
            max="999"
          />{" "}
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

export default Prices;
