import "./Menu.css";
import { useEffect, useState } from "react";
import axios from "axios";

import MenuSortButtons from "./MenuSortButtons";
import Smoothie from "./Smoothie";

const Menu = () => {
  const [chosenDisplay, setChosenDisplay] = useState("");
  const [sortedSmoothies, setSortedSmoothies] = useState({});
  const [smoothies, setSmoothies] = useState([]);

  useEffect(() => {
    const getSmoothies = async () => {
      console.log("Fetching smoothies...");
      const fetchedSmoothies = await axios.get("/api/smoothies");
      console.log("Fetched smoothies:");
      console.log(fetchedSmoothies);
      setSmoothies(fetchedSmoothies.data);
    };

    getSmoothies();
  }, []);

  // Sort smoothies into sections
  useEffect(() => {
    if (smoothies.length === 0) return;
    const sections = {};
    for (let i = 0; i < smoothies.length; i++) {
      if (!sections.hasOwnProperty(smoothies[i].section)) {
        sections[smoothies[i].section] = [smoothies[i]];
      } else {
        sections[smoothies[i].section] = [
          ...sections[smoothies[i].section],
          smoothies[i],
        ];
      }
    }

    setSortedSmoothies(sections);
  }, [smoothies]);

  return (
    <div className="Menu">
      <div className="decor"></div>
      <div className="decor"></div>

      <MenuSortButtons setChosenDisplay={setChosenDisplay} />
      <div className="menu-display">
        {Object.keys(sortedSmoothies).map((section, i) => {
          // If section name is not selected in MenuSortButtons, do not display
          if (chosenDisplay !== section.toLowerCase() && chosenDisplay !== "") {
            return null;
          }

          return (
            <>
              <section className="smoothie-section" key={i}>
                <h3>{section.toLowerCase()}</h3>
                <div className="smoothies-in-section">
                  {sortedSmoothies[section].map((smoothie, j) => {
                    return <Smoothie smoothie={smoothie} key={j} />;
                  })}
                </div>
              </section>
              <hr />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Menu;
