const NutritionFacts = ({
  editing,
  chosenNutritionFacts,
  setChosenNutritionFacts,
}) => {
  const handleChange = (key, e) => {
    setChosenNutritionFacts((prev) => {
      return { ...prev, [key]: parseInt(e.target.value) };
    });
  };

  return (
    <div className="nutrition-facts">
      <div className="fact">
        <label htmlFor="calories">Calories</label>
        <input
          disabled={!editing}
          type="number"
          id="calories"
          value={chosenNutritionFacts["calories"]}
          min="0"
          onChange={(e) => handleChange("calories", e)}
        />
      </div>

      <div className="fact">
        <label htmlFor="total_fat">Total Fat (g)</label>
        <input
          disabled={!editing}
          type="number"
          id="total_fat"
          value={chosenNutritionFacts["total_fat"]}
          min="0"
          onChange={(e) => handleChange("total_fat", e)}
        />
      </div>

      <div className="fact">
        <label htmlFor="saturated_fat">Saturated Fat (g)</label>
        <input
          disabled={!editing}
          type="number"
          id="saturated_fat"
          value={chosenNutritionFacts["saturated_fat"]}
          min="0"
          onChange={(e) => handleChange("saturated_fat", e)}
        />
      </div>

      <div className="fact">
        <label htmlFor="trans_fat">Trans Fat (g)</label>
        <input
          disabled={!editing}
          type="number"
          id="trans_fat"
          value={chosenNutritionFacts["trans_fat"]}
          min="0"
          onChange={(e) => handleChange("trans_fat", e)}
        />
      </div>

      <div className="fact">
        <label htmlFor="cholesterol">Cholesterol (mg)</label>
        <input
          disabled={!editing}
          type="number"
          id="cholesterol"
          value={chosenNutritionFacts["cholesterol"]}
          min="0"
          onChange={(e) => handleChange("cholesterol", e)}
        />
      </div>

      <div className="fact">
        <label htmlFor="sodium">Sodium (mg)</label>
        <input
          disabled={!editing}
          type="number"
          id="sodium"
          value={chosenNutritionFacts["sodium"]}
          min="0"
          onChange={(e) => handleChange("sodium", e)}
        />
      </div>

      <div className="fact">
        <label htmlFor="total_carbs">Total Carbs (g)</label>
        <input
          disabled={!editing}
          type="number"
          id="total_carbs"
          value={chosenNutritionFacts["total_carbs"]}
          min="0"
          onChange={(e) => handleChange("total_carbs", e)}
        />
      </div>

      <div className="fact">
        <label htmlFor="dietary_fiber">Dietary Fiber (g)</label>
        <input
          disabled={!editing}
          type="number"
          id="dietary_fiber"
          value={chosenNutritionFacts["dietary_fiber"]}
          min="0"
          onChange={(e) => handleChange("dietary_fiber", e)}
        />
      </div>

      <div className="fact">
        <label htmlFor="sugars">Sugars (g)</label>
        <input
          disabled={!editing}
          type="number"
          id="sugars"
          value={chosenNutritionFacts["sugars"]}
          min="0"
          onChange={(e) => handleChange("sugars", e)}
        />
      </div>

      <div className="fact">
        <label htmlFor="protein">Protein (g)</label>
        <input
          disabled={!editing}
          type="number"
          id="protein"
          value={chosenNutritionFacts["protein"]}
          min="0"
          onChange={(e) => handleChange("protein", e)}
        />
      </div>
    </div>
  );
};

export default NutritionFacts;
