const Ingredients = ({
  editing,
  chosenIngredients,
  setChosenIngredients,
  ingredients,
}) => {
  // Remove from list if selected ingredient is already checked, or add to list if not already checked
  const handleChange = (ingredient) => {
    setChosenIngredients((prevChosenIngredients) => {
      if (prevChosenIngredients.includes(ingredient)) {
        return prevChosenIngredients.filter(
          (listedIngredient) => listedIngredient !== ingredient
        );
      } else {
        return [...prevChosenIngredients, ingredient];
      }
    });
  };

  return (
    <div className="ingredients">
      {ingredients.map((ingredient, i) => {
        return (
          <div>
            <input
              type="checkbox"
              id={ingredient}
              defaultChecked={
                chosenIngredients && chosenIngredients.includes(ingredient)
              }
              disabled={!editing}
              onChange={() => handleChange(ingredient)}
            />
            <label htmlFor={ingredient}>{ingredient}</label>
          </div>
        );
      })}
    </div>
  );
};

export default Ingredients;
