import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { useRef, useState } from "react";
import { IconContext } from "react-icons";
import useOnOutsideClick from "../hooks/useOnOutsideClick";
import useDisableBodyScroll from "../hooks/useDisableBodyScroll";

import { MdOutlineDeliveryDining } from "react-icons/md";
import { IoStorefrontOutline } from "react-icons/io5";

import logo from "../assets/ECSCircleLogo.jpg";
import vikingfoodlogo from "../assets/VikingFoodDelivery.png";
import doordashlogo from "../assets/doordash.png";
import useOnScroll from "../hooks/useOnScroll";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [orderSelectionOpen, setOrderSelectionOpen] = useState(false);
  const [isShrunk, setIsShrunk] = useState(false);

  const orderSelectionRef = useRef(null);

  useDisableBodyScroll(mobileMenuOpen);

  useOnScroll(window, () => {
    setIsShrunk((isShrunk) => {
      if (mobileMenuOpen) {
        return false;
      }

      if (
        !isShrunk &&
        (document.body.scrollTop > 20 ||
          document.documentElement.scrollTop > 20)
      ) {
        return true;
      }

      if (
        isShrunk &&
        document.body.scrollTop < 4 &&
        document.documentElement.scrollTop < 4
      ) {
        return false;
      }

      return isShrunk;
    });
  });

  useOnOutsideClick(orderSelectionRef, () => setOrderSelectionOpen(false));

  return (
    <nav className={`Navbar${isShrunk ? " shrunk" : ""}`}>
      <NavLink to="/">
        <img src={logo} className="logo" alt="Emerald City Smoothie" />
      </NavLink>
      <ul className={`${mobileMenuOpen ? "open" : ""}`}>
        <img src={logo} className="logo" alt="Emerald City Smoothie"></img>
        <li onClick={() => setMobileMenuOpen(false)}>
          <NavLink to="/">home</NavLink>
        </li>
        <li onClick={() => setMobileMenuOpen(false)}>
          <NavLink to="/menu">menu</NavLink>
        </li>
        <li onClick={() => setMobileMenuOpen(false)}>
          <NavLink to="/contact">contact</NavLink>
        </li>
        <div className="order-link">
          we deliver!
          <div className="delivery-services">
            <a href="https://www.ordervikingfood.com/r/1580/restaurants/delivery/Smoothies-Cafe/Emerald-City-Smoothie-Bellingham">
              <img
                src={vikingfoodlogo}
                className="delivery-icon"
                alt="Viking Food Delivery"
              />
            </a>
            <a href="https://www.doordash.com/store/emerald-city-smoothie-bellingham-970293/">
              <img
                src={doordashlogo}
                className="delivery-icon"
                style={{ height: "80px" }}
                alt="Doordash Delivery"
              />
            </a>
          </div>
        </div>
        <button
          className="close-button"
          onClick={() => setMobileMenuOpen(false)}
        >
          <div className="line"></div>
          <div className="line"></div>
        </button>
      </ul>
      <div className="order">
        <button
          className="order-button"
          onClick={() => setOrderSelectionOpen(true)}
        >
          Order Now
        </button>
        {orderSelectionOpen && (
          <div className="order-type-selection" ref={orderSelectionRef}>
            <a href="https://order.online/store/-970293/en-US/?hideModal=true&pickup=true">
              <div className="pickup-selection">
                <IconContext.Provider
                  value={{ color: "#1a1a1a", size: "1.5em" }}
                >
                  <div>
                    <IoStorefrontOutline />
                  </div>
                </IconContext.Provider>
                <p>Pickup from store</p>
                <p>Ready in 5-10 minutes</p>
              </div>
            </a>
            <a href="https://www.order.store/store/emerald-city-smoothie-bellingham/hLfnBgkjVtODfg3OEgoMoQ">
              <div className="delivery-selection">
                <IconContext.Provider
                  value={{ color: "#1a1a1a", size: "1.5em" }}
                >
                  <div>
                    <MdOutlineDeliveryDining />
                  </div>
                </IconContext.Provider>

                <p>Delivered to home</p>
                <p>Dropped off in ~1 hour</p>
                <p>Or your preferred dropoff time</p>
              </div>
            </a>
          </div>
        )}
      </div>

      <button
        className="hamburger-icon"
        onClick={() => setMobileMenuOpen(true)}
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </button>
    </nav>
  );
};

export default Navbar;
