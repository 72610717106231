import { useRef, useState } from "react";
import useOnOutsideClick from "../../hooks/useOnOutsideClick";
import useDisableBodyScroll from "../../hooks/useDisableBodyScroll";

const Smoothie = ({ smoothie }) => {
  const [showSmoothieModal, setShowSmoothieModal] = useState(false);

  const modalRef = useRef(null);

  useDisableBodyScroll(showSmoothieModal);
  useOnOutsideClick(modalRef, () => setShowSmoothieModal(false));

  const nutritionFacts = JSON.parse(smoothie.nutrition_facts);

  return (
    <>
      <div className="smoothie" onClick={() => setShowSmoothieModal(true)}>
        <div className="smoothie-picture">
          <img
            src={
              smoothie.image_url
                ? smoothie.image_url
                : "/berryberry.jpg"
            }
            alt={smoothie.name}
          />
        </div>
        <div className="smoothie-text">
          <h5 className="smoothie-name">{smoothie.name}</h5>
          <div className="smoothie-ingredients">{smoothie.ingredients}</div>
          <div className="smoothie-prices">
            {smoothie["24oz_price"] === 0 ? null : (
              <span>24oz. ${smoothie["24oz_price"].toFixed(2)} / </span>
            )}
            <span>32oz. ${smoothie["32oz_price"].toFixed(2)}</span>
          </div>
        </div>
      </div>

      {showSmoothieModal && (
        <div className="modal-background">
          <div className="smoothie-modal" ref={modalRef}>
            <div className="information">
              <h5>{smoothie.name}</h5>
              <hr />
              <h6>Ingredients:</h6>
              <p>{smoothie.ingredients}</p>
              <ul>
                {smoothie.vegan !== 0 && <li>Vegan</li>}
                {smoothie.gluten_free !== 0 && <li>Gluten Free</li>}
              </ul>
            </div>
            <div className="nutrition-facts">
              <div className="non-table">
                <p>
                  Serving size:{" "}
                  <span>{smoothie["24oz_price"] !== 0 ? "24oz" : "32oz"}</span>
                </p>
                <div className="calorie-display">
                  <div className="calories">{nutritionFacts.calories}</div>
                  <span>kcal</span>
                </div>
              </div>

              <table>
                <tr>
                  <td>Protein</td>
                  <td>{nutritionFacts.protein} g</td>
                </tr>
                <tr>
                  <td>Total Fat</td>
                  <td>{nutritionFacts.total_fat} g</td>
                </tr>
                <tr>
                  <td>Sat. Fat</td>
                  <td>{nutritionFacts.saturated_fat} g</td>
                </tr>
                <tr>
                  <td>Trans Fat</td>
                  <td>{nutritionFacts.trans_fat} g</td>
                </tr>
                <tr>
                  <td>Cholesterol</td>
                  <td>{nutritionFacts.cholesterol} mg</td>
                </tr>
                <tr>
                  <td>Sodium</td>
                  <td>{nutritionFacts.sodium} mg</td>
                </tr>
                <tr>
                  <td>Total Carbs</td>
                  <td>{nutritionFacts.total_carbs} g</td>
                </tr>
                <tr>
                  <td>Fiber</td>
                  <td>{nutritionFacts.dietary_fiber} g</td>
                </tr>
                <tr>
                  <td>Sugars</td>
                  <td>{nutritionFacts.sugars} g</td>
                </tr>
              </table>
            </div>
            <button onClick={() => setShowSmoothieModal(false)}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Smoothie;
