import { useEffect } from "react";

const useOnScroll = (element, callback) => {
  useEffect(() => {
    if (!element) {
      return;
    }
    element.addEventListener("scroll", callback);

    return () => element.removeEventListener("scroll", callback);
  }, [element, callback]);
};

export default useOnScroll;
