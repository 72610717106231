import { useEffect } from "react";

const useDisableBodyScroll = (modalOpen) => {
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalOpen]);
};

export default useDisableBodyScroll;
