import { useRef, useState } from "react";
import useOnOutsideClick from "../../../hooks/useOnOutsideClick";
import NutritionFacts from "../columns/NutritionFacts";
import Ingredients from "../columns/Ingredients";
import axios from "axios";

const AddSmoothieModal = ({
  setModalOpen,
  ingredients,
  sections,
  setReload,
}) => {
  const [newName, setNewName] = useState("");
  const [newSection, setNewSection] = useState("Go Green");
  const [newIngredients, setNewIngredients] = useState([]);
  const [newGlutenFree, setNewGlutenFree] = useState(0);
  const [newVegan, setNewVegan] = useState(0);
  const [newMediumPrice, setNewMediumPrice] = useState(0);
  const [newLargePrice, setNewLargePrice] = useState(0);
  const [newNutritionFacts, setNewNutritionFacts] = useState({
    calories: 0,
    total_fat: 0,
    saturated_fat: 0,
    trans_fat: 0,
    cholesterol: 0,
    sodium: 0,
    total_carbs: 0,
    dietary_fiber: 0,
    sugars: 0,
    protein: 0,
  });
  const [newPicture, setNewPicture] = useState(null);

  const modalRef = useRef(null);
  useOnOutsideClick(modalRef, () => setModalOpen(false));

  const handleUpload = async () => {
    console.log("Sending request to insert smoothie to db");

    const formData = new FormData();
    formData.append("image", newPicture);
    formData.append("name", `${newName}`);
    formData.append("section", `${newSection}`);
    formData.append("ingredients", `${newIngredients.join(", ")}`);
    formData.append("glutenFree", `${newGlutenFree}`);
    formData.append("vegan", `${newVegan}`);
    formData.append("mediumPrice", `${newMediumPrice}`);
    formData.append("largePrice", `${newLargePrice}`);
    formData.append("nutritionFacts", JSON.stringify(newNutritionFacts));

    await axios.post("/api/smoothies/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    setModalOpen(false);
    window.location.reload();
  };

  return (
    <div className="AdminModal" ref={modalRef}>
      <h2>Add A New Smoothie</h2>
      <hr />
      <div>
        <div>
          <label htmlFor="smoothie-name">Name:</label>
          <input
            id="smoothie-name"
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="section">Section:</label>
          <select
            name="section"
            id="section"
            onChange={(e) => setNewSection(e.target.value)}
            value={newSection}
          >
            {sections.map((section, i) => {
              return (
                <option key={i} value={section}>
                  {section}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div>
        <label htmlFor="ingredients">Ingredients:</label>
        <Ingredients
          chosenIngredients={newIngredients}
          setChosenIngredients={setNewIngredients}
          editing={true}
          ingredients={ingredients}
        />
      </div>
      <div>
        <div>
          <label>Gluten Free:</label>
          <div className="radio-btns">
            <div>
              <input
                id="gluten_free_true"
                name="gf_status"
                type="radio"
                onChange={() => setNewGlutenFree(1)}
              />
              <label htmlFor="gluten_free_true">True</label>
            </div>
            <div>
              <input
                id="gluten_free_false"
                name="gf_status"
                type="radio"
                onChange={() => setNewGlutenFree(0)}
              />
              <label htmlFor="gluten_free_false">False</label>
            </div>
          </div>
        </div>
        <div>
          <label>Vegan:</label>
          <div className="radio-btns">
            <div>
              <input
                id="vegan_true"
                name="vegan_status"
                type="radio"
                onChange={() => setNewVegan(1)}
              />
              <label htmlFor="vegan_true">True</label>
            </div>
            <div>
              <input
                id="vegan_false"
                name="vegan_status"
                type="radio"
                onChange={() => setNewVegan(0)}
              />
              <label htmlFor="vegan_false">False</label>
            </div>
          </div>
        </div>
        <div>
          <label>Nutrition Facts:</label>
          <NutritionFacts
            chosenNutritionFacts={newNutritionFacts}
            setChosenNutritionFacts={setNewNutritionFacts}
            editing={true}
          />
        </div>
        <div>
          <label htmlFor="new_image">Upload Smoothie Picture:</label>
          <input
            type="file"
            name="new_image"
            id="new_image"
            onChange={(e) => setNewPicture(e.target.files[0])}
          />
          {newPicture && (
            <div>
              <img
                alt="not found"
                width={"250px"}
                src={URL.createObjectURL(newPicture)}
              />
              <button onClick={() => setNewPicture(null)}>Remove</button>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="prices">
          <div>
            <label htmlFor="24oz_price">24oz. Price: $</label>
            <input
              id="24oz_price"
              type="number"
              min="0.00"
              max="999"
              defaultValue={0}
              onChange={(e) => setNewMediumPrice(parseInt(e.target.value))}
            />
            <span style={{ fontSize: "13px" }}>
              {
                "(If 24oz is not available as an option, mark 24oz price as $0.00)"
              }
            </span>
          </div>
          <div>
            <label htmlFor="32oz_price">32oz. Price: $</label>
            <input
              id="32oz_price"
              type="number"
              min="0.00"
              max="999"
              defaultValue={0}
              onChange={(e) => setNewLargePrice(parseInt(e.target.value))}
            />
          </div>
        </div>
      </div>
      <button onClick={handleUpload}>Add To Database</button>
    </div>
  );
};

export default AddSmoothieModal;
