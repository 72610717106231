import { useRef, useState } from "react";
import useOnOutsideClick from "../../../hooks/useOnOutsideClick";
import axios from "axios";

const AddSectionModal = ({ sections, setModalOpen, setReload }) => {
  const [newSectionName, setNewSectionName] = useState("");

  const modalRef = useRef(null);
  useOnOutsideClick(modalRef, () => setModalOpen(false));

  const handleClick = async () => {
    setModalOpen(false);

    console.log("Requesting section addition...");
    const addSection = await axios.post("/api/smoothies/sections", {
      name: newSectionName,
    });
    console.log(addSection.data);

    setReload((prev) => !prev);
  };
  return (
    <div className="AdminModal" ref={modalRef}>
      <h2>Add A New Section</h2>
      <hr />
      <div>
        <label htmlFor="current-sections">Current Sections:</label>
        <div id="current-sections">
          <h3>{sections.join(", ")}</h3>
        </div>
      </div>
      <div>
        <label htmlFor="add-section">Section To Add:</label>
        <input
          id="new-section"
          type="text"
          value={newSectionName}
          onChange={(e) => setNewSectionName(e.target.value)}
          placeholder="e.g. Weekly Special"
        />
      </div>
      <button onClick={handleClick}>Add To Database</button>
    </div>
  );
};

export default AddSectionModal;
