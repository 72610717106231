import axios from "axios";

const credentialLoginRequest = async (email, password) => {
  console.log("Requesting login...");
  const response = await axios.post("/api/login", {
    email: email,
    password: password,
  });
  console.log(response.data);

  return response.status === 200;
};

const sessionIdLoginRequest = async () => {
  console.log("Attempting to login with a session id...");

  const response = await axios.post("/api/login/session", {});
};

export { credentialLoginRequest, sessionIdLoginRequest };
