const GlutenFree = ({
  editing,
  smoothie,
  chosenGlutenFree,
  setChosenGlutenFree,
}) => {
  return (
    <>
      <div>
        <input
          type="radio"
          id={`${smoothie.name}_gf_true`}
          name={`${smoothie.name}_gf_status`}
          disabled={!editing}
          defaultChecked={chosenGlutenFree ? "checked" : ""}
          onChange={() => setChosenGlutenFree(1)}
        />
        <label for={`${smoothie.name}_gf_true`}>True</label>
      </div>
      <div>
        <input
          type="radio"
          id={`${smoothie.name}_gf_false`}
          name={`${smoothie.name}_gf_status`}
          disabled={!editing}
          defaultChecked={chosenGlutenFree ? "" : "checked"}
          onChange={() => setChosenGlutenFree(0)}
        />
        <label for={`${smoothie.name}_gf_false`}>False</label>
      </div>
    </>
  );
};

export default GlutenFree;
