import "./Contact.css";
import { useState } from "react";
import emailjs from "@emailjs/browser";

import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();

    const params = {
      to_name: name,
      to_email: email,
      message: message,
    };

    emailjs
      .send(
        "service_6bv5quk",
        "template_c8cwbdb",
        params,
        "user_0hYTlz6Tj87gdtKnWoQot"
      )
      .then((response) => {
        alert("Your email has been sent!");
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="Contact">
      <div className="content-container">
        <div className="contact-info">
          <a
            className="block location"
            href="https://goo.gl/maps/7J1pBvg4FV9w6jAdA"
          >
            <FaMapMarkerAlt className="block-icon" />
            <h4>our store</h4>
            <p>
              1058 Lakeway Dr<span>, Bellingham WA</span>
            </p>
          </a>
          <a href="tel:13606472357" className="block phone">
            <FaPhoneAlt className="block-icon" />
            <h4>
              phone <span>number</span>
            </h4>
            <p>360-647-2357</p>
          </a>
          <a href="mailto:info@emeraldcitysmoothie.net" className="block email">
            <FaEnvelope className="block-icon" />
            <h4>email</h4>
            <p>
              <div>info@</div>
              <div>emeraldcitysmoothie.net</div>
            </p>
          </a>
        </div>
        <form className="contact-form" onSubmit={sendEmail}>
          <h1>Contact Us</h1>
          <hr />
          <p>We'd ♡ to help!</p>
          <p>
            We want to serve you as best as possible, so let us know if you have
            any questions!
          </p>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            name="to_name"
            placeholder="Name"
            required
          />
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            name="to_email"
            placeholder="Email"
            required
          />
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            name="message"
            placeholder="Message"
            rows="5"
            required
          />
          <input type="submit" value="Submit" />
        </form>
      </div>
    </div>
  );
};

export default Contact;
