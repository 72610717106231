import { useRef, useState } from "react";
import Ingredients from "../columns/Ingredients";
import useOnOutsideClick from "../../../hooks/useOnOutsideClick";
import axios from "axios";

const AddIngredientModal = ({ ingredients, setModalOpen, setReload }) => {
  const [newIngredientName, setNewIngredientName] = useState("");

  const modalRef = useRef(null);
  useOnOutsideClick(modalRef, () => setModalOpen(false));

  const handleClick = async () => {
    setModalOpen(false);

    console.log("Requesting ingredient addition...");
    const addIngredient = await axios.post("/api/smoothies/ingredients", {
      name: newIngredientName,
    });
    console.log(addIngredient.data);

    setReload((prev) => !prev);
  };

  return (
    <div className="AdminModal" ref={modalRef}>
      <h2>Add A New Ingredient</h2>
      <hr />
      <div>
        <label htmlFor="current-ingredients">Current Ingredients:</label>
        <Ingredients editing={false} ingredients={ingredients} />
      </div>
      <div>
        <label htmlFor="add-ingredient">Ingredient To Add:</label>
        <input
          id="new-ingredient"
          type="text"
          value={newIngredientName}
          onChange={(e) => setNewIngredientName(e.target.value)}
          placeholder="e.g. Honey"
        />
      </div>
      <button onClick={handleClick}>Add To Database</button>
    </div>
  );
};

export default AddIngredientModal;
