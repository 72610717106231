import "./Admin.css";
import { useEffect, useState } from "react";
import axios from "axios";

import TableRow from "./TableRow";
import Login from "./Login";
import AddSmoothieModal from "./modals/AddSmoothieModal";
import AddIngredientModal from "./modals/AddIngredientModal";
import AddSectionModal from "./modals/AddSectionModal";

import useDisableBodyScroll from "../../hooks/useDisableBodyScroll";

const Admin = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [smoothies, setSmoothies] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [sections, setSections] = useState([]);
  const [addSmoothieModalOpen, setAddSmoothieModalOpen] = useState(false);
  const [addIngredientModalOpen, setAddIngredientModalOpen] = useState(false);
  const [addSectionModalOpen, setAddSectionModalOpen] = useState(false);
  const [reload, setReload] = useState(true);
  const [csvFileText, setCsvFileText] = useState("");

  useDisableBodyScroll(addSmoothieModalOpen);

  // Get smoothies, ingredients, and sections from database
  useEffect(() => {
    const getSmoothies = async () => {
      const fetchedSmoothies = await axios.get("/api/smoothies");
      setSmoothies(fetchedSmoothies.data);
    };
    const getIngredients = async () => {
      const fetchedIngredients = await axios.get("/api/smoothies/ingredients");
      setIngredients(fetchedIngredients.data);
    };
    const getSections = async () => {
      const fetchedSections = await axios.get("/api/smoothies/sections");
      setSections(fetchedSections.data);
    };

    getSmoothies();
    getIngredients();
    getSections();
  }, [reload]);

  // Sort smoothies alphabetically
  useEffect(() => {
    setSmoothies((prev) => {
      const unsortedSmoothies = prev;

      return unsortedSmoothies.sort((a, b) => {
        if (a.section < b.section) {
          return -1;
        }
        if (a.section > b.section) {
          return 1;
        }
        return 0;
      });
    });
  }, [reload]);

  // Setup CSV file to be downloaded when smoothie database is modified
  useEffect(() => {
    let output =
      "Name, Section, Ingredients, 24oz. Price, 32oz. Price, Gluten Free, Vegan, Nutrition Facts, Image URL \r\n";

    smoothies.forEach((smoothie) => {
      let row = "";

      for (let key in smoothie) {
        if (key === "vegan" || key === "gluten_free") {
          row += (smoothie[key] ? "true" : "false") + '",';
        } else {
          row += '"' + smoothie[key].toString().replaceAll('"', "'") + '",';
        }
      }

      row = row.substring(0, row.length - 1); //remove last comma
      row += "\r\n"; //add end of row

      output += row;
    });

    setCsvFileText(output);
  }, [reload, smoothies]);

  return (
    <div className="Admin">
      {!loggedIn ? (
        <Login setLoggedIn={setLoggedIn} />
      ) : (
        <>
          <div className="modify-db-buttons">
            <button onClick={() => setAddSmoothieModalOpen(true)}>
              Add New Smoothie
            </button>
            <button onClick={() => setAddIngredientModalOpen(true)}>
              Add New Ingredient
            </button>
            <button onClick={() => setAddSectionModalOpen(true)}>
              Add New Section
            </button>
            <button>
              <a
                href={`data:text/csv;charset=utf-8,${escape(csvFileText)}`}
                download="smoothies.csv"
              >
                Download CSV
              </a>
            </button>
          </div>

          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Section</th>
                <th>Ingredients</th>
                <th>Gluten Free?</th>
                <th>Vegan?</th>
                <th>Prices</th>
                <th>Nutrition Facts</th>
              </tr>
            </thead>
            <tbody>
              {smoothies.map((smoothie, i) => {
                return (
                  <TableRow
                    smoothie={smoothie}
                    ingredients={ingredients}
                    sections={sections}
                    key={i}
                    setReload={setReload}
                  />
                );
              })}
            </tbody>
          </table>
          {addSmoothieModalOpen && (
            <AddSmoothieModal
              setModalOpen={setAddSmoothieModalOpen}
              ingredients={ingredients}
              sections={sections}
              setReload={setReload}
            />
          )}
          {addIngredientModalOpen && (
            <AddIngredientModal
              setModalOpen={setAddIngredientModalOpen}
              ingredients={ingredients}
              setReload={setReload}
            />
          )}
          {addSectionModalOpen && (
            <AddSectionModal
              setModalOpen={setAddSectionModalOpen}
              sections={sections}
              setReload={setReload}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Admin;
